export const carsData = [
    {
        name: '2019 Honda HR-V 1.8 Executive Auto',
        used: true,
        mileage: '52 500',
        transmissionType: 'Automatic',
        price: ' 329 900',
        fuelType: 'Petrol',
        year: '2019',
        brand: 'Honda',
        imageUrl: require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Main.jpg'),
        imageUrls: [
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Main.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Front.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1731.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1732.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1733.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1734.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1748.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1753.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1754.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1756.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1757.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1758.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1761.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/IMG_1762.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Main2.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Rear Main.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Rear.jpg'),
            require('./images/ 2019 Honda HR-V 1.8 Executive Auto/Rear2.jpg'),
        ],
        extras: [],
        sellerComments: `
            Just in! In excellent condition with only 52000km. Honda's popular HR-V is the perfect family SAV. 
            Stylish & Practical Including BALANCE OF PLAN. Extras include: Rear park sensors, Heated Seats, 
            Reverse Camera, Bluetooth, Towbar, Touch Screen display, Keyless start, Keyless Entry, Leather seats, 
            Climate Control and more. We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... 
            Trade Ins Welcome... We Deliver Countrywide.
        `,
        vehicleDetails: {
            previousOwners: 2,
            serviceHistory: 'Full Franchise Service History',
            colour: 'Grey',
            bodyType: 'SUV',
            warranty: 'Has Warranty',
        },
    },

    {
        name: '2016 Ford Fiesta 5-Door 1.0T Titanium Auto',
        used: true,
        year: '2016',
        mileage: '89,100 km',
        transmissionType: 'Automatic',
        fuelType: 'Petrol',
        price: '189,900',
        imageUrl: require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.29_fb47f5b4.jpg'),
        imageUrls: [
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.29_fb47f5b4.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.34_330a56aa.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.34_340b681f.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.40_189ee0bf.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.43_66588bc3.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.44_3e811762.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.44_6de881c4.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.44_a0e3a6de.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.45_9f720017.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.45_341af0dc.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.45_895fe076.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.47_0fbbe6bd.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.49_12cc74d6.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.51_3138ffac.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.52_6b8ac2e4.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.54_f421a0f0.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.56_67c683aa.jpg'),
        ],
        sellerComments: `
      JUST IN! R3600 SERVICE JUST COMPLETED AT MOTUS FORD CAPE TOWN. This is a one owner car, with low mileage. Great on fuel consumption. Extras include Xenon headlights, Bluetooth, Tip-in Mirrors, Heated Seats, Leather Seats, Rear Park Distance Sensors, Keyless Entry, Push Start, Climate Control, USB, Cruise Control.  We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome... We Deliver Countrywide...
    `,
        vehicleDetails: {
            lastUpdated: '26 February 2024',
            previousOwners: '1',
            serviceHistory: 'Full Franchise Service History',
            colour: 'White',
            bodyType: 'Hatchback',
            extras: [
                'Heated seats - front',
                'Leather upholstery',
                'Park distance control (PDC) - rear',
            ],
        },
    },

    {
        name: '2014 VW Golf Gti 2.0 DSG',
        used: true,
        mileage: '108 800',
        transmissionType: 'Manual',
        price: ' 309 900',
        fuelType: 'Petrol',
        year: '2014',
        brand: 'Volkswagen',
        imageUrl: require('./images/2014 VW Golf Gti 2.0 DSG/Main (1).jpg'),
        imageUrls: [
            require('./images/2014 VW Golf Gti 2.0 DSG/Main (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Main2 (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1630.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1632.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1636.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1637.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1642.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1642 (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1646.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1646 (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1652.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1653.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1654.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1657.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1659.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1661.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1664.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1731.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1732.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1733.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/IMG_1734.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Main2 (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Main (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Rear2 (2).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Rear (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Rear Main (1).jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Rim.jpg'),
            require('./images/2014 VW Golf Gti 2.0 DSG/Rim2.jpg'),
        ],
        extras: ['Panoramic roof', 'Xenon headlights'],
        sellerComments: `
            What an incredible sporty hatch. Just in & ready for its new home. 
            Don't lose out on this beauty. Includes: Paddle Shift, Heated Seats, 
            Heated Side Mirrors, Leather Seats, Climate Control. Contact us now! 
            We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available. 
            Trade-Ins Welcome. We Deliver Countrywide!
        `,
        vehicleDetails: {
            previousOwners: 2,
            serviceHistory: 'Full Service History',
            colour: 'Dark Grey',
            bodyType: 'Hatchback',
        },
    },

    {
        name: '2014 Jaguar F-Type 5.0 V8 S',
        used: false,
        mileage: '42 000',
        transmissionType: 'Automatic',
        price: ' 639 900',
        fuelType: 'Petrol',
        year: '2014',
        brand: 'Jaguar',
        imageUrl: require('./images/2014 Jaguar F-Type 5.0 V8 S/Main.jpg'),
        imageUrls: [
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Main.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Main2.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1777.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1778.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1779.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1781.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1783.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1784.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1811.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1812.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1820.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1821.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/IMG_1824.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Rear.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Rear2.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Rear Main.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Rim.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Rim2.jpg'),
            require('./images/2014 Jaguar F-Type 5.0 V8 S/Tail end.jpg'),
        ],
        sellerComments: `
            JUST IN! Powerful, Sexy & so much fun. This beautiful powerhouse 5.0L V8 convertible is ready for her new home. 
            We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome... We Deliver Countrywide...
        `,
        vehicleDetails: {
            previousOwners: 2,
            serviceHistory: 'Full Franchise Service History',
            colour: 'Black',
            bodyType: 'Cabriolet',
        },
        extras: ['Camera for park distance control', 'Heated seats - front'],
    },
    {
        name: '2023 BMW X7 xDrive40d M Sport',
        used: true,
        mileage: '11 000',
        transmissionType: 'Automatic',
        price: ' 1 999 900',
        fuelType: 'Diesel',
        year: '2023',
        brand: 'Bmw',
        imageUrl: require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333500.jpeg'),
        imageUrls: [
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333500.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333493.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333494.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333496.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333498.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333499.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333492.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333502.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333503.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333504.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333505.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333506.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333507.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333508.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333509.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333511.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333512.jpeg'),
            require('./images/R 1 999 900 | 2023 BMW X7 xDrive40d M Sport For Sale/27333515.jpeg'),
        ],
        extras: [
            'Heated seats - rear',
            'Massaging seats - front',
            'Panoramic roof',
            'Sunroof',
        ],
        vehicleDetails: {
            previousOwners: 1,
            serviceHistory: 'Full Franchise Service History',
            colour: 'White',
            bodyType: 'SUV',
            warranty: '50 Months',
        },
    },
    {
        name: '2011 Mini Cooper Convertible',
        used: true,
        mileage: '151 200',
        transmissionType: 'Manual',
        price: ' 149 900',
        fuelType: 'Petrol',
        year: '2011',
        brand: 'Mini',
        imageUrl: require('./images/2011 Mini Cooper Convertible/Main2.jpg'),
        imageUrls: [
            require('./images/2011 Mini Cooper Convertible/Main2.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1667.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1678.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1682.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1684.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1685.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1686.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1708.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1711.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1712.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1713.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1714.jpg'),
            require('./images/2011 Mini Cooper Convertible/IMG_1717.jpg'),
            require('./images/2011 Mini Cooper Convertible/Rear.jpg'),
            require('./images/2011 Mini Cooper Convertible/Rear Main.jpg'),
            require('./images/2011 Mini Cooper Convertible/Rim.jpg'),
        ],
        extras: [
            'Climate control / automatic air conditioning',
            'Leather upholstery',
            'Xenon headlights',
        ],
        sellerComments: `
            JUST IN & JUST IN TIME FOR SUMMER! A beautiful, fun & trendy drop top, 
            in a stunning blue & tan leather combination. Features include: Leather Seats, 
            Xenon headlights, Climate Control, Electric Windows & Mirrors, Rear Park Sensors. 
            We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... 
            Trade Ins Welcome... We Deliver Countrywide...
        `,
        vehicleDetails: {
            previousOwners: 2,
            serviceHistory: 'Full Service History',
            colour: 'Horizon Blue',
            bodyType: 'Cabriolet',
        },
    },

    {
        name: '2018 Mercedes Benz A200 Amg Auto',
        used: true,
        mileage: '117 000',
        transmissionType: 'Auto',
        price: ' 359 900',
        fuelType: 'Diesel',
        year: '2018',
        brand: 'Mercedes',
        imageUrl: require('./images/2018 Mercedes Benz A200 Amg Auto/27055242.jpg'),
        imageUrls: [
            require('./images/2018 Mercedes Benz A200 Amg Auto/27055242.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9255.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9210.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9214.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9215.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9219.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9220.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9221.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9223.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9224.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9227.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9229.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9230.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9231.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9241.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9243.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9244.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9201.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9263.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/IMG_9266.jpg'),
            require('./images/2018 Mercedes Benz A200 Amg Auto/27055243.jpg'),
        ],
        extras: [
            'Autodim exterior mirrors',
            'Camera for park distance control',
            'Climate control / automatic air conditioning',
            'High-beam assist',
            'Keyless access+start / hands-free key',
            'Light sensor / automatic light control (auto on-off lights)',
            'Panoramic roof',
            'Park distance control (PDC) - front',
            'Park distance control (PDC) - rear',
            'Rain sensor (auto wipers)',
            'Sunroof',
            'Xenon headlights',
        ],
        sellerComments: `
            This fuel-efficient AMG is sure to sell fast. Including features such as: Mountain Grey,
            Active Park Assist, AMG bodystyling, AMG Sport, Dark-tinted glass, Diamond grille, KEYLESS-GO,
            Comfort Access, LED static headlamps, Light & Sight package, Mirror package, Night package,
            Panoramic sunroof, Preinstallation GarminMAP, Reversing camera. This vehicle is a must-see.
            Don't miss the opportunity to own this incredible machine.
            We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome...
            We Deliver Countrywide... Contact Ismael for Excellent Service
        `,
        vehicleDetails: {
            previousOwners: 2,
            serviceHistory: 'Full Franchise Service History',
            colour: 'Grey',
            bodyType: 'Hatchback',
        },
    },

    {
        name: '2016 BMW 340i M Sport',
        used: true,
        mileage: '98,000',
        transmissionType: 'Auto',
        price: '449 900',
        fuelType: 'Petrol',
        year: '2016',
        brand: 'BMW',
        imageUrl: '/home/saeed/Downloads/IMG_8632-HDR.jpg',
        imageUrls: [
            require('./images/2016 BMW 340i Msport Auto/IMG_8632-HDR.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8713.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8712.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8710.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8708.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8705.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8704.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8703.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8698.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8696.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8694.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8692.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8683.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8672.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8660-HDR.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8659-HDR.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8652-HDR.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8643.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8637-HDR.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8633-HDR.jpg'),
            require('./images/2016 BMW 340i Msport Auto/IMG_8714.jpg'),
        ],
        extras: [
            'Autodim exterior mirrors',
            'Camera for park distance control',
            'Climate control / automatic air conditioning',
            'High-beam assist',
            'Keyless access+start / hands-free key',
            'Light sensor / automatic light control (auto on-off lights)',
            'Sunroof',
            'Xenon headlights',
        ],
        sellerComments: `
            This is a stunning BMW 340i M Sport. It's in excellent condition and has been well-maintained.
            The car is loaded with features including xenon headlights, sunroof, and more. Don't miss out
            on this opportunity to own a fantastic BMW. Contact us for more details.
        `,
        vehicleDetails: {
            previousOwners: 1,
            serviceHistory: 'Full Service History',
            colour: 'Black',
            bodyType: 'Sedan',
        },
    },

    {
        name: '2018 Audi A1 1.0TFSi Stronic',
        used: true,
        mileage: '60,400',
        transmissionType: 'Stronic',
        price: '259 900',
        fuelType: 'Petrol',
        year: '2018',
        brand: 'Audi',
        imageUrl: require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_123248.jpg'),
        imageUrls: [
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_123248.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_123231.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_123057.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122850.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122616.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122517.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122505.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122423.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122356.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122341.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122319.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122305.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122256.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122120.jpg'),
            require('./images/2018 Audi A1 1.0TFSi Stronic/20231204_122100.jpg'),
        ],
        extras: [
            'On-board computer / multi-information display',
            'Panoramic roof',
            'Rain sensor (auto wipers)',
            'Tyre pressure sensor/monitor / defflation detectionsystem',
        ],
        sellerComments: `
            This is a sleek 2018 Audi A1 1.0TFSi with Stronic transmission. It's in fantastic condition 
            with a clean interior. Equipped with modern amenities and a smooth, efficient ride, this Audi A1 
            offers both style and comfort. Get in touch to book a test drive or to get more information.
        `,
        vehicleDetails: {
            previousOwners: 2,
            serviceHistory: 'Full Franchise Service History',
            colour: 'Silver',
            bodyType: 'Hatchback',
        },
    },
    {
        name: '2016 Audi RSQ3 Quattro',
        used: true,
        mileage: '123,500',
        transmissionType: 'Automatic',
        price: '449 900',
        fuelType: 'Petrol',
        year: '2016',
        brand: 'Audi',
        imageUrl: require('./images/2016 Audi RSQ3 2.3 Quattro/Main.jpg'),
        imageUrls: [
            require('./images/2016 Audi RSQ3 2.3 Quattro/Main.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/Main2.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/Front (1).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-16.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-17 (2).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-18.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-19.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-20.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-21.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-21 (2).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-21 (3).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-22 (2).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-23.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-23 (2).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-23 (3).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-24.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-24 (3).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-24 (4).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-24 (5).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-25.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-25 (2).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-25 (3).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-26.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/photo_2023-12-22_14-22-26 (2).jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/Rear.jpg'),
            require('./images/2016 Audi RSQ3 2.3 Quattro/Rear Main2.jpg'),
        ],
        extras: ['Hill-start assist / hill holder', 'Panoramic roof'],
        sellerComments: `
            JUST IN!!! Hot Rod Red never looked this good. This beautifully maintained RSQ3 is ready for its new home 
            & for exhilarating new journeys. Contact us now and we'll get you behind the wheel of this beauty in no time. 
            We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome... We Deliver Countrywide...
        `,
        vehicleDetails: {
            previousOwners: 'Unknown',
            serviceHistory: 'Full Franchise Service History',
            colour: 'Red',
            bodyType: 'SUV',
        },
    },

    {
        name: '2017 Ford Fiesta 5-door 1.4 Ambiente',
        used: true,
        mileage: '87,800',
        transmissionType: 'Manual',
        price: '169 900',
        fuelType: 'Petrol',
        year: '2017',
        brand: 'Ford',
        imageUrl: require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.41_ba5f2db1.jpg'),
        imageUrls: [
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.41_ba5f2db1.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.34_21da8d79.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.36_3dbae56f.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.37_9657caa0.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.38_909c3181.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.39_89af2bc2.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.40_8eeebb12.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.30_0d124388.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.41_eca0d41c.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.42_63f80cf0.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.43_9bda4dc8.jpg'),
            require('./images/2017 Ford Fiesta 5-door 1.4 Ambiente/WhatsApp Image 2024-02-22 at 19.30.43_099d4ee3.jpg'),
        ],
        sellerComments: `
    JUST IN! Light on fuel and very reliable. This one's sure to sell fast. We specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome... We Deliver Countrywide.
    `,
        vehicleDetails: {
            lastUpdated: '22 February 2024',
            previousOwners: 3,
            serviceHistory: 'Full Service History',
            colour: 'Blue',
            bodyType: 'Hatchback',
        },
    },

    {
        name: '2016 Ford Fiesta 5-Door 1.0T Titanium Auto',
        used: true,
        year: '2016',
        brand: 'Ford',
        mileage: '89,100',
        transmissionType: 'Automatic',
        fuelType: 'Petrol',
        price: '189 900',
        imageUrl: require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.49_12cc74d6.jpg'),
        imageUrls: [
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.49_12cc74d6.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.34_330a56aa.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.34_340b681f.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.40_189ee0bf.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.43_66588bc3.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.44_3e811762.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.44_6de881c4.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.44_a0e3a6de.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.45_9f720017.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.45_341af0dc.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.45_895fe076.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.47_0fbbe6bd.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.51_3138ffac.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.52_6b8ac2e4.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.54_f421a0f0.jpg'),
            require('./images/2016 Ford Fiesta 5-Door 1.0T Titanium Auto For Sale/WhatsApp Image 2024-01-24 at 17.57.56_67c683aa.jpg'),
        ],
        sellerComments: `
      JUST IN! R3600 SERVICE JUST COMPLETED AT MOTUS FORD CAPE TOWN. This is a one owner car, with low mileage. Great on fuel consumption. Extras include Xenon headlights, Bluetooth, Tip-in Mirrors, Heated Seats, Leather Seats, Rear Park Distance Sensors, Keyless Entry, Push Start, Climate Control, USB, Cruise Control.  We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome... We Deliver Countrywide...
    `,
        vehicleDetails: {
            lastUpdated: '26 February 2024',
            previousOwners: '1',
            serviceHistory: 'Full Franchise Service History',
            colour: 'White',
            bodyType: 'Hatchback',
            extras: [
                'Heated seats - front',
                'Leather upholstery',
                'Park distance control (PDC) - rear',
            ],
        },
    },

    {
        name: '2007 Porsche Boxster S',
        used: true,
        mileage: '102,000',
        transmissionType: 'Manual',
        price: '349 900',
        fuelType: 'Petrol',
        year: '2007',
        brand: 'Porsche',
        imageUrl: require('./images/2007 Porsche Boxter S /IMG_9045.jpg'),
        imageUrls: [
            require('./images/2007 Porsche Boxter S /IMG_9045.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9053.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9056.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9069.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9074.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9082.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9083.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9084.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9086.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9087.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9090.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9092.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9099.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9111.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9114.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9117.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9118.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9123.jpg'),
            require('./images/2007 Porsche Boxter S /IMG_9125.jpg'),
            require('./images/2007 Porsche Boxter S /Porsche.jpg'),
        ],
        sellerComments: `
    Looking for a thrilling ride that will turn heads and leave you with an exhilarating driving experience? Look no further than this Porsche Boxster S Cabriolet. This beautiful machine has a sleek and stylish design, a retractable soft top, a luxurious interior with many features, and ample storage space. It handles fantastically and provides a smooth and comfortable ride. A great option for those looking for a thrilling and stylish sports car.

    Finance Available... Trade Ins Welcome... We Deliver Countrywide... Contact Ismael for Excellent Service
        `,
        vehicleDetails: {
            colour: 'White',
            bodyType: 'Add body type here',
        },
    },

    {
        name: '2016 BMW 4 Series 440i Convertible M Sport',
        used: true,
        mileage: '75,600',
        price: '599 900',
        brand: 'BMW',
        transmissionType: 'Automatic',
        fuelType: 'Petrol',
        year: '2016',
        imageUrl: require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-27 at 16.23.20_194c1076.jpg'),
        imageUrls: [
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-27 at 16.23.20_194c1076.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-27 at 16.23.20_f7ab5941.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-27 at 16.23.21_1cf47c3d.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-27 at 16.23.21_d1eee836.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-27 at 16.23.21_f10262d5.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-28 at 10.13.08_7f9a24fc.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-28 at 10.13.09_540db28b.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-28 at 10.13.09_a3ff3241.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-28 at 10.13.09_cfca3e5d.jpg'),
            require('./images/2016 BMW 440I Msport Convertible/WhatsApp Image 2024-02-28 at 10.13.10_6481cd16.jpg'),
        ],
        sellerComments: `
      JUST IN! We Specialize in Quality Approved Pre-Owned Vehicles. 
      Finance Available... Trade Ins Welcome... We Deliver Countrywide... 
      Contact Ismael for Excellent Service
    `,
        vehicleDetails: {
            lastUpdated: '29 February 2024',
            previousOwners: 2,
            serviceHistory: 'Full Franchise Service History',
            colour: 'Silver',
            bodyType: 'Cabriolet',
        },
    },

    {
        name: '2012 Ford Ranger 3.2TDCi Double Cab 4x4 XLT',
        used: true,
        mileage: '295 200',
        transmissionType: 'Manual',
        price: '219 900',
        fuelType: 'Diesel',
        year: 2012,
        brand: 'Ford',
        imageUrl: require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120920.jpg'),
        imageUrls: [
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120920.jpg'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_115755.jpg'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_115809.jpg'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120023 (1)-Recovered.jpg'),
            //   require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120223.heic'),
            //   require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120259.heic'),
            //   require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120323.heic'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120355.jpg'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120421.jpg'),
            //   require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120524.heic'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_120956.jpg'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_121147.jpg'),
            require('./images/2012 Ford Ranger 3.2TDCI 4X4 XLT Doubel Cab/20240325_121207.jpg'),
        ],
        extras: [],
        sellerComments: `
      This vehicle has a FULL SERVICE HISTORY WITH FORD. Side steps and bull bar, Towbar, Bluetooth, Tip in mirrors, Cruise control. 
      We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome... We Deliver Countrywide.
    `,
        vehicleDetails: {
            previousOwners: 1,
            serviceHistory: 'Full Franchise Service History',
            colour: 'White',
            bodyType: 'Double cab',
        },
    },

    {
        name: '2018 Mercedes-AMG GLC GLC43 4Matic',
        used: true,
        mileage: '74 500',
        transmissionType: 'Automatic',
        price: '699 900',
        fuelType: 'Petrol',
        year: '2018',
        brand: 'Mercedes-Benz',
        imageUrl: require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153119.jpg'),
        imageUrls: [
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153119.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153143.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153420.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153435.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153452.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153533.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153546.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153554.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153606.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153659.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153713.jpg'),
            require('./images/2018 Mercedes Benz GLC43 AMG 4Matic/20240508_153828.jpg'),
        ],
        extras: [
            'Active/adaptive cruise control',
            'Adaptive headlights (varying light distribution)',
            'Camera for park distance control',
            'High-beam assist',
            'Lane change / blind spot warning/assist/monitor',
            'Navigation',
            'Panoramic roof',
            'Rear side airbags',
            'Surround / top / area view cameras',
        ],
        sellerComments: `
    JUST IN! An absolutely beautiful performance SUV. Packed with extras and ready for it's new home.
    We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade Ins Welcome...
    We Deliver Countrywide... Contact us for Excellent Service
  `,
        vehicleDetails: {
            lastUpdated: '11 May 2024',
            previousOwners: 'Unknown',
            serviceHistory: 'Full Franchise Service History',
            colour: 'White',
            bodyType: 'SUV',
        },
    },

    {
        name: '2006 Mercedes-Benz C-Class C230 V6 Sports Coupe Evolution Auto',
        used: true,
        mileage: '178 500',
        transmissionType: 'Automatic',
        price: '119 900',
        fuelType: 'Petrol',
        year: '2006',
        brand: 'Mercedes-Benz',
        imageUrl: require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141149.jpg'),
        imageUrls: [
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141149.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_135919.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_135938.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_135952.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_140045.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_140125.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_140713.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_140741.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_140938.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141022.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141037.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_135848.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141252.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141411.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141429.jpg'),
            require('./images/2006 Mercedes Benz C230 V6 Auto/20240513_141447.jpg'),
        ],
        extras: ['Xenon headlights'],
        sellerComments: `
    This vehicle has already been through ROADWORTHY. Very neat and well cared for.
    We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available...
    Trade Ins Welcome... We Deliver Countrywide... Contact Ismael for Excellent Service
  `,
        vehicleDetails: {
            lastUpdated: '19 May 2024',
            previousOwners: 2,
            serviceHistory: 'Full Service History',
            colour: 'Silver',
            bodyType: 'Coupé',
        },
    },

    {
        name: '2017 Hyundai Creta 1.6CRDi Executive Auto',
        used: true,
        mileage: '133 000',
        transmissionType: 'Automatic',
        price: '234 900',
        fuelType: 'Diesel',
        year: '2017',
        brand: 'Hyundai',
        imageUrl: require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_111103.jpg'),
        imageUrls: [
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_111103.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_104820.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_105408.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_105517.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_105530.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_105608.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_105858.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_105946.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_110002.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_110722.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_110733.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/20240530_104803.jpg'),
            require('./images/2017 Hyundai Creta 1.6CDRi Auto/WhatsApp Image 2024-05-27 at 14.52.48_f562b0b4.jpg'),
        ],
        extras: [
            'Climate control',
            'LCD interface',
            'Bluetooth',
            'Reverse camera',
            'Rear PDC',
            'Mag wheels',
            'Towbar',
            'Leather seats',
            'Multi-function steering',
            'Electric mirrors with tip-in function',
            'Electric windows',
        ],
        sellerComments:
            'JUST IN! Tyres in excellent condition. This is a great family vehicle that is very fuel efficient. We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available... Trade-Ins Welcome... We Deliver Countrywide... Contact Faraaz for Excellent Service.',
        vehicleDetails: {
            lastUpdated: '19 May 2024',
            previousOwners: 2,
            serviceHistory: 'Full Service History',
            colour: 'White',
            bodyType: 'SUV',
        },
    },
    {
        name: "2011 BMW 3 Series 320d Exclusive Auto",
        used: true,
        mileage: "235 500",
        transmissionType: "Automatic",
        price: "139 900",
        fuelType: "Diesel",
        year: "2011",
        brand: "BMW",
        imageUrl: require('./images/2011 BMW 320D Exclusive Auto/IMG_2463-HDR.jpg'),
        imageUrls: [
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2463-HDR.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2468-HDR.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2474-HDR.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2480.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2481.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2486.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2488-HDR.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2498-HDR.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2503-HDR.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2512.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2513.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2514.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2517.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2518.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2520.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2521.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2522.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2523.jpg'),
            require('./images/2011 BMW 320D Exclusive Auto/IMG_2524.jpg')
        ],
        extras: [
            "Sunroof",
            "Xenon headlights",
            "Rear park sensors",
            "Bluetooth for handsfree",
            "Climate control",
            "Tow bar"
        ],
        sellerComments: "Just in. This vehicle has been well looked after. Fuel efficient and still pretty powerful. We Specialize in Quality Approved Pre-Owned Vehicles. Finance Available. Trade Ins Welcome. We Deliver Countrywide.",
        vehicleDetails: {
            previousOwners: "Unknown",
            serviceHistory: "Full Service History",
            colour: "Grey",
            bodyType: "Sedan"
        }
    }

];
